<script>
import Layout from "../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import ChartGraduation from "../../components/widgets/chart-graduation.vue";
import ChartPoints from "@/components/widgets/chart-points";

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  locales: {
    pt: {
      Team: "Time",
      Dashboard: "Painel",
      Package: "Pacote",
      Graduation: "Graduação",
      Points: "Pontos",
      "Referral link": "Link de indicação",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Pontos",
    },
    es: {
      Team: "Equipo",
      Dashboard: "Panel",
      Package: "Paquete",
      Graduation: "Graduación",
      Points: "Puntos",
      "Referral link": "Link de patrocineo",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Puntos",
    },
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartPoints,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      total: {
        bonus: {
          network: "R$ -,--",
          token: "R$ -,--",
        },
        balance: "R$ -,--",
      },

      monthly: "inactive",

      graduation: {
        id: null,
        name: "-",
        image: null,
      },

      statData: null,
      wallets: null,

      notifications: true,

      cart: {
        items: {
          loading: true,
          total: 0,
          list: null,
        },
        subtotal: "R$ 0,00",
        shipping: {
          method: "-",
          value: "-",
        },
        total: "R$ 0,00",
      },
    };
  },
  methods: {
    getGraduation() {
      api.get("report/graduation").then((response) => {
        if (response.data.status == "success") {
          this.graduation.id = response.data.current.id;
          this.graduation.name = response.data.current.name;
          this.graduation.image = response.data.current.image;
          this.getStatData();
        }
      });
    },
    getMonthly() {
      api.get("report/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getBonus() {
      api.get("wallet/balance/network").then((response) => {
        if (response.data.status == "success") {
          this.total.bonus.network = this.$options.filters.currency(
            response.data.available
          );
          this.getStatData();
        }
      });

      api.get("wallet/balance/token").then((response) => {
        if (response.data.status == "success") {
          this.total.bonus.token = this.$options.filters.currency(
            response.data.available
          );
          this.getStatData();
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          type: "center",
          icon: "status",
          title: "Status",
          value: this.monthly,
        },
        {
          type: "center",
          icon: "monthly",
          title: "Graduation",
          value: this.graduation.name,
        },
        {
          type: "center",
          icon: "network",
          title: "Saldo de Rede",
          value: this.total.bonus.network,
        },
        {
          type: "center",
          icon: "token",
          title: "Saldo de Tokenização",
          value: this.total.bonus.token,
        },
      ];
    },
    checkNotifications() {
      if (firebase.messaging.isSupported()) {
        api.get("user/notifications").then((response) => {
          if (response.data.status === "success") {
            if (response.data.devices == "active") {
              this.notifications = true;
            } else {
              this.notifications = false;
            }
          } else {
            this.notifications = false;
          }
        });
      }
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get("store/cart")
          .then((response) => {
            if (response.data.status == "success") {
              this.setStorage(response.data);
            }
          })
          .catch((error) => {
            this.cart.errored = error;
          });
      } else {
        this.cart = JSON.parse(localStorage.cart);
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total;
      this.cart.items.list = data.items.list;
      this.cart.subtotal = data.subtotal;
      this.cart.shipping = data.shipping;
      this.cart.total = data.total;

      localStorage.cart = JSON.stringify(this.cart);
    },
  },
  mounted() {
    this.getMonthly();
    this.getBonus();
    this.getStatData();
    this.checkNotifications();
    this.getCart();
  },
};
</script>

<template>
  <Layout>
    <router-link
      v-if="!notifications"
      to="/account/notifications"
      class="text-dark"
    >
      <div class="card">
        <div class="card-body bg-soft-info rounded">
          <div class="d-flex align-items-center">
            <i class="bx bx-bell font-size-24 align-middle mr-3"></i>
            <div>
              Fique informado de todas as atualizações do seu escritório
              virtual, ative suas notificações Web Push!
            </div>
          </div>
        </div>
      </div>
    </router-link>

    <router-link v-if="cart.items.total > 0" to="/store/cart" class="text-dark">
      <div class="card">
        <div class="card-body bg-soft-warning rounded p-0">
          <div class="d-flex align-items-center">
            <img
              class="rounded-circle header-profile-user m-2 mx-2"
              :src="cart.items.list[Object.keys(cart.items.list)[0]].image"
              width="30px"
              height="30px"
            />
            <div class="py-3">
              Você deixou alguns produtos em seu carrinho, clique aqui para
              finalizar o seu pedido!
            </div>
          </div>
        </div>
      </div>
    </router-link>

    <Banners></Banners>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-3 col-lg-3 col-xl-3"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center">
              <i class="bx bx-line-chart mr-1"></i> Graduação
            </h5>
            <ChartGraduation />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center">
              <i class="bx bx-line-chart mr-1"></i> {{ t("Total Points") }}
            </h5>
            <ChartPoints></ChartPoints>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t("Referral link") }}
            </h5>
            <p class="m-0 notranslate">
              https://app.gruposmartx.com.br/{{ account.user.username }}
              <button
                class="btn btn-link p-0 ml-2"
                v-clipboard:copy="
                  'https://app.gruposmartx.com.br/' + account.user.username
                "
              >
                <i class="bx bx-copy font-size-18 text-dark"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-none">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t("E-commerce") }}
            </h5>
            <p class="m-0 notranslate">
              https://{{ account.user.username }}.gruposmartx.com.br/
              <button
                class="btn btn-link p-0 ml-2"
                v-clipboard:copy="
                  'https://' + account.user.username + '.gruposmartx.com.br'
                "
              >
                <i class="bx bx-copy font-size-18 text-dark"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>