<script>
import Chart from "@/components/widgets/chart";
import { api } from "@/state/services";

export default {
  components: { Chart },
  data() {
    return {
      empty: true,
      chartdata: { },
      options: { }
    }
  },
  methods: {
    getChart() {
      api
        .get('chart/points')
        .then(response => {
          if (response.data.status=='success') {
            this.chartdata = {
              labels: response.data.chart.labels,
              datasets: [
                {
                  label: 'Por Dia',
                  fill: true,
                  backgroundColor: '#2c0f3f',
                  borderColor: '#2c0f3f',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: '#2c0f3f',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#2c0f3f',
                  pointHoverBorderColor: '#fff',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: response.data.chart.data,
                }
              ]
            }
            this.options = {
              defaultFontColor: '#2c0f3f',
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      max: response.data.max,
                      min: 0,
                      stepSize: response.data.stepSize,
                    },
                    gridLines: {
                      color: 'rgba(235, 28, 36, 0.1)',
                    },
                  },
                ],
              },
            }

            this.empty = false
          } else {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getChart();
  },
}
</script>

<template>
  <div v-if="empty" class="text-center">
    {{ t('No records found.') }}
  </div>
  <div v-else>
    <Chart :chartdata="chartdata" :options="options"></Chart>
  </div>
</template>